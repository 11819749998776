<template>
  <v-container id="dashboard-view" fluid tag="section" class="py-0 my-0">
      <v-row>
        <v-col cols="12">

          <v-card class="mb-5">
            <v-card-text class="py-1">
              <GeonameSelector
                  :country="filters.geonameCountryCode"
                  :region="filters.geonameRegionCode"
                  :province="filters.geonameProvinceCode"
                  @update:country="filters.geonameCountryCode = $event"
                  @update:region="filters.geonameRegionCode = $event"
                  @update:province="filters.geonameProvinceCode = $event"
                  @geonameLoaded="geonameLoaded"
              ></GeonameSelector>
            </v-card-text>
          </v-card>

          <v-row>
            <v-col cols="12">
              <material-card :heading="'GOALS' " color="primary">
                <v-card-text>
                  <MFBullet :key="globalTarget" :target="globalTarget" :actual="globalSales"></MFBullet>
                </v-card-text>

                <v-card-text>
                  <GoalExpandableTable 
                    :collection="goalsData"
                  ></GoalExpandableTable>
                </v-card-text>
                
              </material-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12">
              <MFLineChart :title="'DEFAULT TITLE'" :xAxis="xAxis" :collections="getCollections()"></MFLineChart>
            </v-col>
          </v-row>

          <v-row>
            <v-col v-for="(el, i) in machineStats" :key="i+el.actionText" cols="12" xs="4" sm="4" md="4" lg="4">
              <custom-material-stat-card v-bind="el">
                <template #actions>
                  <div>
                    <b>{{ el.actionText }}</b>
                  </div>
                </template>
              </custom-material-stat-card>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="7">
              <material-card heading="Customers Without C&G" color="primary">
                <v-card-text>
                  <v-sheet>
                    <mf-simple-table :headers="noCeg.headers" :items="noCeg.collectionNoCeg" >
                    </mf-simple-table>
                  </v-sheet>
                </v-card-text>
              </material-card>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="5">
              <material-card heading="Customers Without Orders" color="primary">
                <v-card-text>
                  <v-sheet>
                    <v-card-title>
                      <!-- <v-spacer></v-spacer> -->
                      <v-text-field v-model="colorTab.search" append-icon="mdi-magnify" label="Search" single-line hide-details>
                      </v-text-field>
                    </v-card-title>
                    <v-data-table :headers="colorTab.headers" :items="colorTab.collection" :search="colorTab.search" class="elevation-1">
                      <template v-slot:item.orders="{ item }">
                        <v-chip :color="getColor(item.orders)" dark>
                          {{ item.orders }}
                        </v-chip>
                      </template>
                    </v-data-table>
                  </v-sheet>
                </v-card-text>
              </material-card>
            </v-col>
            <v-col cols="12" md="12" lg="12">
              <material-card heading="Top 5 Coupon Customers" color="primary">
                <v-card-text>
                  <v-sheet>
                    <mf-simple-table :headers="topCoupon.headers" :items="topCoupon.collectionTopCoupon" >
                    </mf-simple-table>
                  </v-sheet>
                </v-card-text>
              </material-card>
            </v-col>
            <v-col v-for="(item, i) in maps" :key="i+item.heading" :md="i !== 0 ? 6 : undefined" cols="12">
              <material-card :heading="item.heading" color="primary">
                <v-card-text>
                  <v-sheet>
                    <!-- <iframe :src="`https://www.google.com/maps/embed?${item.iframe}`" allowfullscreen frameborder="0"
                      height="450" style="border:0" width="100%" /> -->
                      <TelerikMap></TelerikMap>
                  </v-sheet>
                </v-card-text>
              </material-card>
            </v-col>


          </v-row>
        </v-col>





      </v-row>
  </v-container>

</template>


<script>
  // Utilities
  import { call, get, sync } from 'vuex-pathify'
  import Vue from 'vue'
  import MfSimpleTable from '../components/MfSimpleTable.vue'
  import TelerikMap from '../components/TelerikMap.vue'
  import MFLineChart from '../components/charts/MFLineChart.vue'
  import MFBullet from '../components/charts/MFBullet.vue'
  import GoalExpandableTable from '../components/charts/GoalExpandableTable.vue'

  import GeonameSelector from '../components/filters/GeonameSelector.vue'

  const lineSmooth = Vue.chartist.Interpolation.cardinal({
    tension: 0,
  })

  export default {
    components: { 
      MfSimpleTable, 
      TelerikMap, 
      MFBullet,
      GoalExpandableTable,
      GeonameSelector,
      MFLineChart 
    },
    name: 'DashboardView',
    methods: {
      handleFilteredStats() {
        if (!!this.goalResponse && !!this.goalResponse.length) this.globalTarget = Math.floor(this.goalResponse.reduce((a, b) => { a = a || 0; a += b?.totalAmount || 0; return a; }, 0));
        if (!!this.orderResponse && !!this.orderResponse.length) {
          this.globalSales = Math.floor(
              this.orderResponse
              .reduce((a, b) => { 
                a = a || 0; 
                a += b?.totalAmount || 0; 
                return a; 
              }, 0));
        }
      },
      
      handleGeonameSelector(param, value) {
        this.filters[param] = value;
      },

      getColor(param) {
        if (param == 0) return 'error'
        else if (param < 4) return 'warning'
        else return 'success'
      },
      getCollections : function() {
        return [
          { name : "line1", collection : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}, 
          { name : "line2", collection : [1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2]}, 
          { name : "line3", collection : [3, 4, 3, 4, 3, 4, 3, 4, 3, 4, 3, 4]}, 
          { name : "line4", collection : [5, 6, 5, 6, 5, 6, 5, 6, 5, 6, 5, 6]}
        ]
      },
      geonameLoaded() {
        Promise.all([this.apiZoneGoalFilteredGet(), this.apiOrderStatisticalDataFilteredGet()])
        .then((res) => {
          // this.handleFilteredStats();
          this.goalsData.length = 0;
          this.globalTarget = 0;
          this.globalSales = 0;
          this.goalResponse.forEach(el => {
            let zoneName = this.provinces.find(x => x.proviceId == el.provinceCode)?.provinceName || this.regions.find(x => x.regionId == el.regionCode)?.regionName || this.countries.find(x => x.iso == el.countryCode)?.country,
              sales = this.orderResponse
                .filter(x => x.provinceCode == el.provinceCode &&  x.regionCode == el.regionCode && x.countryCode == el.countryCode)
                .reduce((a, b) => { a = a || 0; a += b.totalAmount || 0;  return a; }, 0),
              target = el.totalAmount,
              salesQty = this.orderResponse
                .filter(x => x.provinceCode == el.provinceCode &&  x.regionCode == el.regionCode && x.countryCode == el.countryCode)
                .reduce((a, b) => { a = a || 0; a += +b.totalQuantity || 0;  return a; }, 0),
              targetQty = el.totalQuantity;
            this.goalsData.push({ 
              zoneCode: el.provinceCode || el.regionCode || el.countryCode, 
              zoneName : zoneName, 
              salesAmount : sales, 
              targetAmount: target,
              salesQty : salesQty,
              targetQty : targetQty
            });
            this.globalTarget += target;
            this.globalSales += sales; 
          });
        });

      },
      initialize() {
        this.apiStatisticalTagGet()
        .then(res => {
        });
      },
      ...call('geoname/*'),
      ...call('statistics/*')
    },
    data: () => ({
      xAxis: [this.$moment().month()],
      goalsData : [],
      globalTarget : 0,
      globalSales : 0,


      noCeg : {
        search: '',
        headers: [
          {
            text: 'Code',
            align: 'start',
            value: 'code',
          },
          { text: 'Description', value: 'description' },
          { text: 'Address', value: 'formatted_address' },
        ],
        collectionNoCeg : [{
            code: '1234',
            description: 'Antoine Barabba',
            formatted_address: 'Spalto montebello 141, Castellazzo Bormida, 15073, AL',
          }, {
            code: '1237',
            description: 'Lucas Ribeiro',
            formatted_address: 'Via Carlo Pisacane 21, Alessandria, 15121, AL',
          }, {
            code: '1239',
            description: 'Moussa Sissoko',
            formatted_address: 'Corso Roma 12, Alessandria, 15121, AL',
          }, {
            code: '1245',
            description: 'Karim Goulash',
            formatted_address: 'Via Mazzarello 101, Nizza Monferrato, 14049, AT',
          }, {
            code: '1245',
            description: 'Karim Goulash',
            formatted_address: 'Via Mazzarello 101, Nizza Monferrato, 14049, AT',
          }, {
            code: '1245',
            description: 'Karim Goulash',
            formatted_address: 'Via Mazzarello 101, Nizza Monferrato, 14049, AT',
          }, {
            code: '1245',
            description: 'Karim Goulash',
            formatted_address: 'Via Mazzarello 101, Nizza Monferrato, 14049, AT',
          }, {
            code: '1245',
            description: 'Karim Goulash',
            formatted_address: 'Via Mazzarello 101, Nizza Monferrato, 14049, AT',
          }, {
            code: '1245',
            description: 'Karim Goulash',
            formatted_address: 'Via Mazzarello 101, Nizza Monferrato, 14049, AT',
          }, {
            code: '1245',
            description: 'Karim Goulash',
            formatted_address: 'Via Mazzarello 101, Nizza Monferrato, 14049, AT',
          }, {
            code: '1245',
            description: 'Karim Goulash',
            formatted_address: 'Via Mazzarello 101, Nizza Monferrato, 14049, AT',
          }, {
            code: '1245',
            description: 'Karim Goulash',
            formatted_address: 'Via Mazzarello 101, Nizza Monferrato, 14049, AT',
          }, {
            code: '1245',
            description: 'Karim Goulash',
            formatted_address: 'Via Mazzarello 101, Nizza Monferrato, 14049, AT',
          }
        ],  
      },
      topCoupon : {
        search: '',
        headers: [
          {
            text: 'Code',
            align: 'start',
            value: 'code',
          },
          { text: 'Description', value: 'description' },
          { text: 'Address', value: 'formatted_address' },
        ],
        collectionTopCoupon : [{
            code: '1234',
            description: 'Antoine Barabba',
            formatted_address: 'Spalto montebello 141, Castellazzo Bormida, 15073, AL',
          }, {
            code: '1237',
            description: 'Lucas Ribeiro',
            formatted_address: 'Via Carlo Pisacane 21, Alessandria, 15121, AL',
          }, {
            code: '1239',
            description: 'Moussa Sissoko',
            formatted_address: 'Corso Roma 12, Alessandria, 15121, AL',
          }, {
            code: '1245',
            description: 'Karim Goulash',
            formatted_address: 'Via Mazzarello 101, Nizza Monferrato, 14049, AT',
          }, {
            code: '1245',
            description: 'Karim Goulash',
            formatted_address: 'Via Mazzarello 101, Nizza Monferrato, 14049, AT',
          },
        ],  
      },
      colorTab: {
        search: '',
        headers: [{
            text: 'Code',
            align: 'start',
            value: 'code',
          },
          {
            text: 'Description',
            value: 'description'
          },
          {
            text: 'Orders',
            value: 'orders'
          },
          {
            text: 'Status',
            value: 'status'
          },
        ],
        collection: [{
            code: '3456',
            description: 'Laurent Trentin',
            orders: 0,
            status: 'B'
          },
          {
            code: '3456',
            description: 'Laurent Trentin',
            orders: 0,
            status: 'B'
          },
          {
            code: '3456',
            description: 'Alessandro Gallina',
            orders: 0,
            status: 'L'
          },
          {
            code: '3456',
            description: 'Laurent Trentin',
            orders: 0,
            status: 'B'
          },
          {
            code: '3456',
            description: 'Alberto Quaglia',
            orders: 10,
            status: 'L'
          },
          {
            code: '3456',
            description: 'Laurent Trentin',
            orders: 7,
            status: 'L'
          },
          {
            code: '3456',
            description: 'Luke Skywalker',
            orders: 4,
            status: 'B'
          },
          {
            code: '3456',
            description: 'Laurent Trentin',
            orders: 4,
            status: 'B'
          },
          {
            code: '3456',
            description: 'Power Dinamite',
            orders: 1,
            status: 'L'
          },
          {
            code: '3456',
            description: 'Power Dinamite',
            orders: 1,
            status: 'L'
          },
        ],
      },
      obiettivo: {
        target: 100000,
        prc: 34,
        gained: 34000,
        animate: true
      },
      maps: [{
        heading: 'Satellite Map',
        iframe: 'pb=!1m18!1m12!1m3!1d26356315.701909266!2d-113.65984645545673!3d36.25591957613731!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited+States!5e0!3m2!1sen!2sus!4v1566158729223!5m2!1sen!2sus',
      }, ],
      charts: [{
        type: 'Bar',
        color: 'primary',
        title: 'Website Views',
        subtitle: 'Last Campaign Performance',
        time: 'updated 10 minutes ago',
        data: {
          labels: ['Ja', 'Fe', 'Ma', 'Ap', 'Mai', 'Ju', 'Jul', 'Au', 'Se', 'Oc', 'No', 'De'],
          series: [
            [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
          ],
        },
        options: {
          axisX: {
            showGrid: false,
          },
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          ['screen and (max-width: 640px)', {
            seriesBarDistance: 5,
            axisX: {
              labelInterpolationFnc: function (value) {
                return value[0]
              },
            },
          }],
        ],
      }, {
        type: 'Line',
        color: 'success',
        title: 'Daily Sales',
        subtitle: '<i class="mdi mdi-arrow-up green--text"></i><span class="green--text">55%</span>&nbsp;increase in today\'s sales',
        time: 'updated 4 minutes ago',
        data: {
          labels: ['12am', '3pm', '6pm', '9pm', '12pm', '3am', '6am', '9am'],
          series: [
            [230, 750, 450, 300, 280, 240, 200, 190],
          ],
        },
        options: {
          lineSmooth,
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      }, {
        type: 'Line',
        color: 'info',
        title: 'Completed Tasks',
        subtitle: 'Last Campaign Performance',
        time: 'campaign sent 26 minutes ago',
        data: {
          labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
          series: [
            [12, 17, 7, 17, 23, 18, 38],
          ],
        },
        options: {
          lineSmooth,
          low: 0,
          high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      }],
      headers: [{
          sortable: false,
          text: 'ID',
          value: 'id',
        },
        {
          sortable: false,
          text: 'Name',
          value: 'name',
        },
        {
          sortable: false,
          text: 'Salary',
          value: 'salary',
          align: 'right',
        },
        {
          sortable: false,
          text: 'Country',
          value: 'country',
          align: 'right',
        },
        {
          sortable: false,
          text: 'City',
          value: 'city',
          align: 'right',
        },
      ],
      items: [{
          id: 1,
          name: 'Dakota Rice',
          country: 'Niger',
          city: 'Oud-Tunrhout',
          salary: '$35,738',
        },
        {
          id: 2,
          name: 'Minerva Hooper',
          country: 'Curaçao',
          city: 'Sinaai-Waas',
          salary: '$23,738',
        },
        {
          id: 3,
          name: 'Sage Rodriguez',
          country: 'Netherlands',
          city: 'Overland Park',
          salary: '$56,142',
        },
        {
          id: 4,
          name: 'Philip Chanley',
          country: 'Korea, South',
          city: 'Gloucester',
          salary: '$38,735',
        },
        {
          id: 5,
          name: 'Doris Greene',
          country: 'Malawi',
          city: 'Feldkirchen in Kārnten',
          salary: '$63,542',
        },
      ],
      machineStats: [{
        actionText: 'Tot : 110',
        color: '#FD9A13',
        year: '2020',
        title_1: 'Sold',
        title_2: 'Loan',
        value_1: '100',
        value_2: '10',
      }, {
        actionText: 'Tot : 2',
        color: '#FD9A13',
        year: '2021',
        title_1: 'Sold',
        title_2: 'Loan',
        value_1: '1',
        value_2: '1',
      }, {
        actionText: 'Tot : 112',
        color: '#FD9A13',
        year: 'TOT',
        title_1: 'Sold',
        title_2: 'Loan',
        value_1: '101',
        value_2: '11',
      }],
      stats: [{
          actionIcon: 'mdi-alert',
          actionText: 'Get More Space...',
          color: '#FD9A13',
          icon: 'mdi-store',
          title: 'Shops',
          value: '184',
        },
        {
          actionIcon: 'mdi-tag',
          actionText: 'Tracked from Google Analytics',
          color: 'info',
          icon: 'mdi-chart-bar',
          title: 'Website Visits',
          value: '75.521',
        },
        {
          actionIcon: 'mdi-calendar-range',
          actionText: 'Last 24 Hours',
          color: 'success',
          icon: 'mdi-currency-usd',
          title: 'Revenue',
          value: '$34,245',
        },
        {
          actionIcon: 'mdi-history',
          actionText: 'Just Updated',
          color: '#E1306C',
          icon: 'mdi-instagram',
          title: 'Followers',
          value: '+245',
        },
      ],
      tabs: 0,
      tasks: {
        0: [{
            text: 'Sign contract for "What are conference organizers afraid of?"',
            value: true,
          },
          {
            text: 'Lines From Great Russian Literature? Or E-mails From My Boss?',
            value: false,
          },
          {
            text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
            value: false,
          },
          {
            text: 'Create 4 Invisible User Experiences you Never Knew About',
            value: true,
          },
        ],
        1: [{
            text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
            value: true,
          },
          {
            text: 'Sign contract for "What are conference organizers afraid of?"',
            value: false,
          },
        ],
        2: [{
            text: 'Lines From Great Russian Literature? Or E-mails From My Boss?',
            value: false,
          },
          {
            text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
            value: true,
          },
          {
            text: 'Sign contract for "What are conference organizers afraid of?"',
            value: true,
          },
        ],
      },
    }),
    computed: {
      sales: get('sales/sales'),
      countries : get('geoname/countries'),
      regions : get('geoname/countryRegions'),
      provinces : get('geoname/regionProvinces'),
      goalResponse : sync('statistics/zoneGoalCollection'),
      orderResponse : sync('statistics/orderStatsCollection'),
      filters : sync('statistics/filter'),
      statisticalTags : sync('statistics/statisticalTagCollection')
    }, 
    created() {
      this.initialize();
    },
    
  }
</script>